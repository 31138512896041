import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import pt from './locales/pt-br.json';
import en from './locales/en.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
	resources: {
		pt: { translation: pt },
	},
	fallbackLng: 'pt',
	debug: true,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;