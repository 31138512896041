import HomeMega from '../partials/HomeMega';
import HomeKids from '../partials/HomeKids';

export default function Home () {
	return (
		<main id="main">
			{process.env.REACT_APP_PAGE_TYPE === 'kids' ? <HomeKids /> : <HomeMega />}
		</main>
	);
}
