import { useState, useEffect } from 'react';
import axios from 'axios';

interface UserData {
    ip: string;
    user_agent: string;
}

export const useUserData = () => {
    const [userData, setUserData] = useState<UserData>({ ip: '', user_agent: '' });

    useEffect(() => {
        const getUserData = async () => {
            try {
                const responseConfig = {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_V3_API_TOKEN
                    }
                };

                const dataResponse = await axios.get('https://v3.vitrinemegamodel.com.br/api/user-data', responseConfig);
                
                setUserData({
                    ip: dataResponse.data.ip,
                    user_agent: dataResponse.data.user_agent,
                });
            } catch (error) {
                console.error(error);
            }
        };

        getUserData();
    }, []);

  return userData;
};
